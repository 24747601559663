import ToastService from "../../services/ToastService";

import { useForm } from 'react-hook-form';

import ToPayApi from "../../services/ToPayApi";
import { useEffect, useState } from "react";
import PollarisApi from "../../services/PollarisApi";

const FormStone = ({ idCliente }) => {

    let loading = false;

    const apiToPay = new ToPayApi();

    const apiPollaris = new PollarisApi();

    const toastService = new ToastService();

    const [clienteStone, setClienteStone] = useState({});

    const [cliente, setCliente] = useState({});
    
    const [clienteStoneCarregado, setClienteStoneCarregado] = useState(false);

    useEffect(() => {

        if (idCliente) {

            if (loading) return;

            loading = true;

            loadCliente();
        }
    }, [idCliente]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const loadCliente = () => {

        apiPollaris.get(`clientes/${idCliente}`).then((response) => {

            setCliente(response);

            apiToPay.get(`stone/clientes/${response.cnpj}`).then((responseStone) => {

                console.log(responseStone);

                setClienteStone(responseStone);

                setClienteStoneCarregado(true);

            });

        });
    }



    const onSubmit = (data) => {

        toastService.info("Aguarde..", "Salvando...");

        var clienteAtual = { ...clienteStone };

        const novoCliente = { ...clienteStone, ...data };

        console.log(novoCliente);

        const onSuccess = (response) => {
            toastService.success("Sucesso..", "Salvo com sucesso");
        };

        const onFail = (error) => {

            console.error(error);

            const message = error?.message || error.title;

            toastService.error("Erro ao salvar dados", message);

        }

        if (clienteStone?.id  && clienteStone?.id > 0) {

            apiToPay.put(`stone/clientes`, novoCliente)
                .then(onSuccess)
                .catch(onFail);

        } else {

            apiToPay.post(`stone/clientes`, novoCliente)
                .then(onSuccess)
                .catch(onFail);
        }


    }

    return (
        cliente.id &&
        <div className="card">
            <div className="card-header">
                Stone
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control " {...register('documento', { value: cliente.cnpj, required: true })} />
                        <label>Documento</label>
                    </div>
                    {clienteStoneCarregado &&
                        <div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control " {...register('code', { value: clienteStone?.code, required: true })} />
                                <label>Código (code)</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control " {...register('account', { value: clienteStone?.account, required: true })} />
                                <label>Usuário (account)</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control " {...register('secretKey', { value: clienteStone?.secretKey, required: true })} />
                                <label>Senha (secretKey)</label>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">Salvar</button>
                            </div>
                        </div>
                    }
                </form>
            </div>
        </div>
    );
};

export default FormStone;