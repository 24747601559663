import { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import PollarisApi from "../services/PollarisApi";

import ToastService from "../services/ToastService";

const PageLogin = () => {

  document.title = "ERP | Login";

  return (
    <div className="container">
      <div className="mt-5">
        <div className="col-md-6 offset-md-3 shadow bg-pollaris">
          <div className="row">
            <div className="col-md-5">
              <div className="mt-2"></div>
              <div className="p-4 col-md-12 col-8 offset-md-0 offset-2">
                <img src="/assets/logoPollaris_h.svg" alt="Pollaris" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="p-3">
                <div className="p-5 bg-light shadow">
                  <h5 className="mb-3 text-secondary">
                    ERP
                  </h5>
                  <FormLogin />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FormLogin = () => {


  const apiPollaris = new PollarisApi(true);

  const toastService = new ToastService();


  const [invalidFormClass, setInvalidFormClass] = useState("");

  const [user, setUser] = useState("");

  const [password, setPassword] = useState("");

  const [manterConectado, setManterConectado] = useState(false);

  useEffect(() => {

    const authJson = localStorage.getItem("authentication");

    if (authJson) {

      var auth = JSON.parse(authJson);

      const expired = new Date(auth.expire);

      const now = new Date();

      if (now > expired) {

        localStorage.removeItem("authentication");

      } else {

        window.location.href = "/"
      }
    }

  }, []);

  const logar = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (user === "" || password === "") {
      setInvalidFormClass("was-validated");
      return
    }

    toastService.info('Aguarde..', "Validando informaçoes...")

    const base64Auth = btoa(`${user}:${password}`);

    apiPollaris
      .authenticate(base64Auth)
      .then((response) => {

        response.base64Auth = base64Auth;

        response.keep = manterConectado;

        localStorage.setItem("authentication", JSON.stringify(response));

        window.location.href = "/"

      })
      .catch((error) => {

        toastService.error('Erro..', "Dados inválidos...")

        document.getElementById("user").focus();

        document.getElementById("user").select();

      });

  };

  return (<form onSubmit={logar} className={`needs-validation ${invalidFormClass}`} noValidate >
    <div className="mb-3">
      <div className="input-group input-group shadow border">
        <span className="input-group-text bg-light border-0" id="inputGroup-sizing-lg">
          <i className="bi bi-person"></i>
        </span>
        <input autoFocus id="user" autoComplete="username" placeholder="e-mail" type="text" className="form-control bg-light border-0" required onChange={(e) => setUser(e.target.value)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" />
      </div>
    </div>
    <div className="mb-3">
      <div className="input-group input-group- shadow border">
        <span className="input-group-text bg-light border-0" id="inputGroup-sizing-lg">
          <i className="bi bi-key"></i>
        </span>
        <input placeholder="senha" autoComplete="current-password" type="password" className="form-control bg-light border-0" required onChange={(e) => setPassword(e.target.value)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" />
      </div>
    </div>
    <div className="form-check form-switch mb-3">
      <input onChange={(e) => setManterConectado(e.target.checked)} className="form-check-input" type="checkbox" role="switch" id="checkBoxManterConectado" />
      <label className="form-check-label" htmlFor="checkBoxManterConectado">Manter conectado</label>
    </div>
    <div className="mt-2 mb-3">
      <button
        type="submit"
        className="btn btn-pollaris shadow col-12">
        <i className="bi bi-box-arrow-in-right"></i> {'Entrar'}
      </button>
    </div>
    <div className="mt-2 text-center">
      <Link type="button" className="btn btn-link text-secondary btn-sm" to="/recuperar" style={{ textDecoration: "none" }}>
        Esqueceu sua senha?
      </Link>
    </div>
  </form>);

}

export default PageLogin;