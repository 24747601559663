import { useParams } from "react-router-dom";
import ContratosCliente from "../../components/cliente/ContratosCliente";
import FormCliente from "../../components/cliente/FormCliente";
import Cobrancas from "../../components/cobranca/Cobrancas";
import FormStone from "../../components/integracoes/FormStone";

const PageCliente = (props) => {

    const { id } = useParams();

    document.title = `Pollaris ERP | Cliente ${id}`;

    return (
        <div className="container">
            <div className="card shadow">
                <div className="card-body">
                    <div className="mb-2">
                        <h5>Cliente {id}</h5>
                    </div>
                    <hr />
                    <div className="accordion shadow" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i className="bi bi-file-person"></i>
                                    &ensp;Cadastro
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <FormCliente idCliente={id} />
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseIntegracoes" aria-expanded="true" aria-controls="collapseIntegracoes">
                                    <i className="bi bi-link"></i>
                                    &ensp;Integrações
                                </button>
                            </h2>
                            <div id="collapseIntegracoes" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <FormStone idCliente={id}/>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i className="bi bi-ui-radios"></i>
                                    &ensp;Contratos
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <ContratosCliente idCliente={id} />
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i className="bi bi-currency-dollar"></i>
                                    &ensp;Cobranças
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <Cobrancas idCliente={id}></Cobrancas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageCliente;